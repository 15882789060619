<template>
  <div>
    <FormHeader
      title="Create Tag"
      :subtitle="object.name"
      btnLabel="Save Tag"
      :canAdd="hasPermission('core.add_tag')"
      @submit="submit"
      :saveLoading="$store.state.tags.object.loading"
    />

    <v-form ref="form">
      <v-card>
        <v-toolbar dark flat dense color="primary">
          <v-icon left>mdi-account</v-icon>
          <v-toolbar-title> Tag </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="object.name" label="Name" :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="object.client"
                label="Client"
                :items="clients.items"
                :loading="clients.loading"
                item-text="text"
                item-value="value"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { hasPermission } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { FormHeader } from '@/spaday/components'

export default {
  components: {
    FormHeader,
  },
  data: () => ({
    object: {},
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => v.length >= 8 || 'At least 8 characters',
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      object: (state) => state.tags.object,
      clients: (state) => state.clients.combobox,
    }),
  },
  mounted() {
    this.getClients()
  },
  methods: {
    hasPermission,
    ...mapActions('tags', {
      createTag: 'create',
    }),
    ...mapActions('clients', {
      getClients: 'getCombobox',
    }),
    submit() {
      if (!this.$refs.form.validate()) return

      this.createTag(this.object).then(() => {
        this.$router.go(-1)
      })
    },
  },
}
</script>
