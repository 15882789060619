import { List, Update, Create } from '@/views/tag'

const routes = [
  {
    path: '/tag',
    name: 'Tag',
    component: List,
    meta: {
      public: false,
      permissions: ['core.view_tag'],
    },
  },
  {
    path: '/tag/add',
    name: 'AddTag',
    component: Create,
    meta: {
      public: false,
      permissions: ['core.add_tag'],
    },
  },
  {
    path: '/tag/:pk/change',
    name: 'ChangeTag',
    component: Update,
    meta: {
      public: false,
      permissions: ['core.change_tag'],
    },
  },
]

export default routes
