<template>
  <div>
    <ListHeader
      title="Tags"
      subtitle="Create and manage tags."
      btnLabel="New Tag"
      :route="{ name: 'AddTag' }"
      :canAdd="hasPermission('core.add_tag')"
    />

    <v-card>
      <v-card-title class="text--primary">
        <SearchField label="Search Tags..." @search="updateSearch" />
      </v-card-title>
      <v-data-table
        v-model="tagTable.selected"
        item-key="name"
        loading-text="Loading. Please wait..."
        :loading="tags.loading"
        :items="tags.items"
        :headers="tagTable.headers"
        :search="tagTable.search"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100],
          'show-current-page': true,
        }"
        :options.sync="tagTable.options"
        :server-items-length="tagTable.totalItems"
        @update:options="getPaginatedTags"
        @update:sort-by="sortTable"
        @update:sort-desc="sortOrder"
        @update:page="page"
        :sort-by="dt.column"
        :sort-desc="dt.order"
        :page="dt.page"
      >
        <template v-slot:item.client="{ item }">
          {{ item.owner_display }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ localDatetime(item.created_at) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <RowActions :actions="tagTable.actions" @edit="editTag(item)" @confirm-delete="preDeleteTag(item)" />
        </template>
      </v-data-table>
    </v-card>

    <DeleteConfirmation @confirm-delete="deleteTag" />
  </div>
</template>

<script>
import { hasPermission, localDatetime, getPaginationParams } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { DeleteConfirmation, ListHeader, RowActions, SearchField } from '@/spaday/components'

export default {
  components: {
    RowActions,
    DeleteConfirmation,
    ListHeader,
    SearchField,
  },
  data: () => ({
    tagTable: {
      options: {
        itemsPerPage: 20,
      },
      totalItems: 0,
      search: '',
      selected: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Owner', value: 'client' },
        { text: 'Created', value: 'created_at' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      actions: [
        {
          text: 'Edit Tag',
          icon: 'mdi-pencil',
          color: 'primary',
          permissions: ['core.change_tag'],
          emit: 'edit',
        },
        {
          text: 'Delete Tag',
          icon: 'mdi-trash-can',
          color: 'error',
          permissions: ['core.delete_tag'],
          emit: 'confirm-delete',
        },
      ],
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      tags: (state) => state.tags.all,
      dt: (state) => state.datatable,
    }),
  },
  mounted() {
    console.log(this.dt.search)
    // this.getClients()
    if (this.dt.search != '') {
      setTimeout(() => {
        this.updateSearch(this.dt.search)
      }, 100)
    }
  },
  methods: {
    hasPermission,
    getPaginationParams,
    localDatetime,
    ...mapActions('tags', {
      getTags: 'getAll',
      preDeleteTag: 'preDelete',
      deleteTag: 'delete',
    }),
    ...mapActions('datatable', {
      setSearch: 'setSearch',
      setColumn: 'setColumn',
      setOrder: 'setOrder',
      setPage: 'setPageNumber',
    }),
    editTag(item) {
      this.$router.push({ name: 'ChangeTag', params: { pk: item.pk } })
    },

    /** Pagination handlers **/
    getPaginatedTags() {
      const params = this.getPaginationParams(this.tagTable.options, this.tagTable.search)
      this.getTags(params).then((data) => {
        this.tagTable.totalPages = data.num_pages
        this.tagTable.totalItems = data.num_results
      })
    },
    /** Datatable handlers **/
    updateSearch(value) {
      this.tagTable.search = value
      this.setSearch(value)
      this.getPaginatedTags()
    },
    sortTable(value) {
      if (value != undefined) {
        this.setColumn(value)
      } else {
        this.setColumn('')
      }
    },
    sortOrder(value) {
      if (value != undefined) {
        this.setOrder(value)
      } else {
        this.setOrder('')
      }
    },
    page(value) {
      this.setPage(value)
    },
  },
}
</script>
