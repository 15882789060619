import { v4 as uuidv4 } from 'uuid'

export const VERSION = '0.1.1'
export const PROJECT_NAME = 'app Admin'
export const API_URL = 'https://xapp.xstudios.com/api/v1'

export const MQTT_CONNECTION = {
  port: 443,
  protocol: 'wss',
  endpoint: '/mqtt',
  useSSL: true,

  clean: true, // Set to false to receive QoS 1 and 2 messages while offline
  connectTimeout: 30 * 1000,
  reconnectPeriod: 1 * 1000,
  clientId: `xapp-dashboard-${uuidv4().substring(0, 8)}`,
  keepalive: 30,
}

export const TOPIC_BROKER = '$SYS/broker'

export const SIDENAV = [
  {
    icon: 'mdi-view-dashboard',
    title: 'Dashboard',
    to: {
      name: 'Dashboard',
    },
    meta: {
      permissions: ['django_spaday.view_dashboard'],
    },
  },
  {
    icon: 'mdi-chart-bar',
    title: 'Broker Stats',
    to: {
      name: 'Broker Stats',
    },
    meta: {
      permissions: ['django_spaday.view_brokerdashboard'],
    },
  },
  {
    icon: 'mdi-list-status',
    title: 'Agents',
    to: {
      name: 'Agents',
    },
    meta: {
      permissions: ['core.can_view_agent'],
    },
  },
  {
    divider: true,
  },
  //   {
  //     header: 'Users',
  //   },
  {
    icon: 'mdi-account',
    title: 'Users',
    to: {
      name: 'User',
    },
    meta: {
      permissions: ['users.view_user', 'users.add_user', 'users.change_user', 'users.delete_user'],
    },
  },
  {
    icon: 'mdi-account-group',
    title: 'Groups',
    to: {
      name: 'Group',
    },
    meta: {
      permissions: ['auth.view_group', 'auth.add_group', 'auth.change_group', 'auth.delete_group'],
    },
  },
  {
    icon: 'mdi-clipboard-text-clock-outline',
    title: 'Audit Log',
    to: {
      name: 'AuditLog',
    },
    meta: {
      permissions: [
        'auditlog.view_logentry',
        'auditlog.add_logentry',
        'auditlog.change_logentry',
        'auditlog.delete_logentry',
      ],
    },
  },
  //   {
  //     icon: 'mdi-calendar-check',
  //     title: 'Celery Task Results',
  //     to: {
  //       name: 'TaskResult',
  //     },
  //     meta: {
  //       permissions: [
  //         'django_celery_results.view_taskresult',
  //         'django_celery_results.add_taskresult',
  //         'django_celery_results.change_taskresult',
  //         'django_celery_results.delete_taskresult',
  //       ],
  //     },
  //   },
  //   {
  //     icon: 'mdi-account-group',
  //     title: 'Auth',
  //     active: true,
  //     items: [
  //       { title: 'Users', to: { name: 'User' }, chip: true },
  //       { title: 'Groups', to: { name: 'Group' }, chip: true },
  //     ],
  //   },
  {
    divider: true,
  },
  //   {
  //     header: 'Xapp Config',
  //   },
  {
    icon: 'mdi-account-multiple',
    title: 'Clients',
    to: {
      name: 'Client',
    },
    meta: {
      permissions: ['core.view_client', 'core.add_client', 'core.change_client', 'core.delete_client'],
    },
  },
  {
    icon: 'mdi-cellphone-link',
    title: 'Devices',
    to: {
      name: 'Device',
    },
    meta: {
      permissions: ['core.view_device', 'core.add_device', 'core.change_device', 'core.delete_device'],
    },
  },
  {
    icon: 'mdi-tag-multiple',
    title: 'Tags',
    to: {
      name: 'Tag',
    },
    meta: {
      permissions: ['core.view_tag', 'core.add_tag', 'core.change_tag', 'core.delete_tag'],
    },
  },
  {
    icon: 'mdi-console-line',
    title: 'Terminal Commands',
    to: {
      name: 'TerminalCommand',
    },
    meta: {
      permissions: [
        'core.view_terminalcommand',
        'core.add_terminalcommand',
        'core.change_terminalcommand',
        'core.delete_terminalcommand',
      ],
    },
  },
]
