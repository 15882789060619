/**
 * The vue router defines all of the routes for the application, and contains
 * a function that runs before each route change to prevent unauthenticated
 * users from accessing restricted routes.
 */

import Vue from 'vue'
import VueRouter from 'vue-router'
//import store from '@/store/'

import { datatable } from '@/store/data-table.module'

import { auth_routes, user_routes, group_routes, auditlog_routes, taskresult_routes } from '@/spaday/router'
import { PageNotFound, AdminBase } from '@/spaday/views'

import client_routes from '@/router/client.js'
import terminal_routes from '@/router/terminal-commands.js'
import device_routes from '@/router/device.js'
import tag_routes from '@/router/tag.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'Dashboard' },
    component: AdminBase,
    children: [
      {
        path: '/dash',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dash" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/broker-stats',
        name: 'Broker Stats',
        component: () => import('@/views/BrokerStats.vue'),
      },
      {
        path: '/agents',
        name: 'Agents',
        component: () => import('@/views/Agents.vue'),
      },
    ]
      .concat(user_routes)
      .concat(group_routes)
      .concat(auditlog_routes)
      .concat(taskresult_routes)
      .concat(client_routes)
      .concat(terminal_routes)
      .concat(device_routes)
      .concat(tag_routes),
    meta: {
      public: false,
    },
  },
  // and finally the default route, when none of the above matches
  {
    path: '*',
    component: PageNotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes: routes.concat(auth_routes),
  base: '/admin/',
})

router.beforeEach((to, from, next) => {
  const authenticated = sessionStorage.getItem('loggedIn')
  const onlyLoggedOut = to.matched.some((record) => record.meta.onlyLoggedOut)
  const isPublic = to.matched.some((record) => record.meta.public)
  if (!isPublic && !authenticated) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return next({
      name: 'Login',
      query: {
        redirect: to.fullPath,
      },
    })
  }
  if (authenticated && onlyLoggedOut) {
    return next({
      name: 'Dashboard',
    })
  }
  // check to see if the user has left the child route (Client,Devices, etc)
  if (!to.path.includes(from.path) && !from.path.includes(to.path)) {
    datatable.state.column = ''
    datatable.state.search = ''
    datatable.state.order = ''
    datatable.state.page = 1
  }

  next()
})

export default router
