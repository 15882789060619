<template>
  <div>
    <FormHeader
      title="Update Tag"
      :subtitle="object.name"
      btnLabel="Update Tag"
      :canChange="hasPermission('core.change_tag')"
      :canDelete="hasPermission('core.delete_tag')"
      @submit="submit"
      @delete="preDeleteTag(object)"
      :deleteLoading="$store.state.tags.delete.loading"
      :saveLoading="$store.state.tags.object.loading"
    />

    <v-form ref="form" :disabled="object.loading" :readonly="!hasPermission('core.change_tag')">
      <v-card class="mb-4" :loading="object.loading">
        <v-toolbar dark flat dense color="primary">
          <v-icon left>mdi-information-variant</v-icon>
          <v-toolbar-title> General Info </v-toolbar-title>
        </v-toolbar>

        <v-divider class="primary"></v-divider>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="object.name" label="Name" :rules="[rules.required]"></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                v-model="object.client"
                label="Client"
                :items="clients.items"
                :loading="clients.loading"
                item-text="text"
                item-value="value"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions
          ><v-spacer></v-spacer>
          <div class="caption">
            Tag ID <strong>{{ object.pk }}</strong>
          </div></v-card-actions
        >
      </v-card>
    </v-form>

    <DeleteConfirmation @confirm-delete="deleteRedirect" />
  </div>
</template>

<script>
import { hasPermission, localDatetime } from '@/spaday/helpers'
import { mapState, mapActions } from 'vuex'
import { FormHeader, DeleteConfirmation } from '@/spaday/components'

export default {
  components: {
    DeleteConfirmation,
    FormHeader,
  },
  data: () => ({
    rules: {
      required: (value) => !!value || 'Required.',
      min: (v) => v.length >= 8 || 'At least 8 characters',
    },
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      object: (state) => state.tags.object,
      clients: (state) => state.clients.combobox,
    }),
  },
  mounted() {
    this.getTag(this.$route.params.pk)
    this.getClients()
  },
  beforeDestroy() {
    this.clearTag()
  },
  methods: {
    hasPermission,
    localDatetime,
    ...mapActions('tags', {
      getTag: 'get',
      updateTag: 'update',
      preDeleteTag: 'preDelete',
      deleteClient: 'delete',
      clearTag: 'clear',
    }),
    ...mapActions('clients', {
      getClients: 'getCombobox',
    }),
    submit() {
      if (!this.$refs.form.validate()) return

      this.updateTag({ id: this.object.pk, data: this.object }).then(() => {
        this.$router.go(-1)
      })
    },
    deleteRedirect(object) {
      this.deleteClient(object).then(() => {
        this.$router.go(-1)
      })
    },
  },
}
</script>
